<template>
  <div>
    <div v-if="!bulkAddMode" >
      <DataTable :value="localSerialNumbers" dataKey="id">
        <Column field="serial_number" header="Serial Number">
          <template #body="slotProps">
            <InputText
                v-if="editableRowId === slotProps.data.id"
                v-model="slotProps.data.serial_number"
                class="w-full p-2 border border-gray-300 rounded"
            />
            <span v-else>{{ slotProps.data.serial_number }}</span>
          </template>
        </Column>

        <Column :styles="{ width: '20%' }">
          <template #body="slotProps">
            <div class="flex justify-end items-center">
              <AppButton
                  v-if="editableRowId === slotProps.data.id"
                  color="blue"
                  label=""
                  :icon="true"
                  class="mr-1"
                  @click="saveRow(slotProps.data)"
              >
                <template v-slot:icon>
                  <Icon iconType="save" />
                </template>
              </AppButton>
              <AppButton
                  v-if="editableRowId === slotProps.data.id && localSerialNumbers.length !== 0"
                  color="gray"
                  label=""
                  :icon="true"
                  class="mr-1"
                  @click="cancelEdit(slotProps.data)"
              >
                <template v-slot:icon>
                  <Icon iconType="close" />
                </template>
              </AppButton>
              <AppButton
                  v-else
                  color="blue"
                  label=""
                  :icon="true"
                  class="mr-1"
                  @click="editRow(slotProps.data.id)"
              >
                <template v-slot:icon>
                  <Icon iconType="edit" />
                </template>
              </AppButton>
              <AppButton
                  color="red"
                  label=""
                  :icon="true"
                  class="mr-1"
                  @click="deleteRow(slotProps.data.id)"
              >
                <template v-slot:icon>
                  <Icon iconType="delete" />
                </template>
              </AppButton>
              <AppButton
                  color="green"
                  v-if="isLastRow(slotProps.data.id)"
                  label=""
                  :icon="true"
                  class="mr-1"
                  @click="addSerialNumber"
              >
                <template v-slot:icon>
                  <Icon iconType="add-notes" />
                </template>
              </AppButton>
            </div>
          </template>
        </Column>
      </DataTable>

      <div class="flex justify-between items-center mt-4">
        <div class="flex items-center">
          <AppButton
              color="green"
              label="Multiple Quick Add"
              :icon="true"
              class="mr-4"
              @click="toggleBulkAddMode"
          >
            <template v-slot:icon>
              <Icon iconType="circle-plus" class="mr-2" />
            </template>
          </AppButton>
        </div>

        <div class="flex items-center">
          <span class="font-semibold mr-2">Outstanding To Add:</span>
          <span>{{ outstandingQuantity }}</span>
        </div>
      </div>
    </div>

    <div v-if="bulkAddMode" >
      <textarea
          v-model="bulkSerialNumbers"
          class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-3 px-4 focus:shadow w-full"
          placeholder="Enter serial numbers separated by commas"
          rows="10"
      ></textarea>
      <div class="flex justify-between items-center mt-4">
        <div class="flex items-center">
          <span class="font-semibold mr-2">Remaining Quantity:</span>
          <span>{{ remainingQuantityForBulkAdd }}</span>
        </div>

        <div class="flex items-center">
          <AppButton
              color="green"
              label="Save"
              :icon="true"
              class="mr-4"
              @click="saveBulkSerialNumbers"
          >
            <template v-slot:icon>
              <Icon iconType="save"  class="mr-1"/>
            </template>
          </AppButton>
          <AppButton
              color="gray"
              label="Cancel"
              :icon="true"
              class="mr-4"
              @click="toggleBulkAddMode"
          >
            <template v-slot:icon>
              <Icon iconType="close"  class="mr-1"/>
            </template>
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin"; // Assuming you have a notification mixin


export default {
  mixins: [notificationMixin],
  components: { Icon, AppButton },
  props: {
    initialSerialNumbers: {
      type: Array,
      required: true,
      default: () => [],
    },
    sales_order_id: {
      type: String,
      required: true,
    },
    dispatch_note_id: {
      type: String,
      required: true,
    },
    pack_list_id: {
      type: String,
      required: true,
    },
    pack_list_item_id: {
      type: String,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localSerialNumbers: [],
      editableRowId: null,
      originalSerialNumberData: {},
      bulkAddMode: false,
      bulkSerialNumbers: ''
    };
  },
  computed: {
    outstandingQuantity() {
      return this.quantity - this.localSerialNumbers.filter((item) => !item.isNew).length;
    },

    remainingQuantityForBulkAdd() {
      const serials = this.bulkSerialNumbers.split(',').filter(
          (sn) => sn.trim().length >= 5
      );
      debugger
      return this.quantity - this.localSerialNumbers.filter((item) => !item.isNew).length - serials.length;
    },
  },
  mounted() {
    this.localSerialNumbers = _.cloneDeep(this.initialSerialNumbers); // Initialize with deep copy of serial numbers
    this.checkAndAddInitialSerialNumber();
  },
  methods: {

    toggleBulkAddMode() {
      this.bulkAddMode = !this.bulkAddMode;
      this.bulkSerialNumbers = '';
    },

    checkAndAddInitialSerialNumber() {
      if (this.localSerialNumbers.length === 0) {
        this.addSerialNumber();
      }
    },

    addSerialNumber() {
      if (this.editableRowId != null) {
        this.notifyWarning("Please save the current item before adding a new one.");
        return;
      }

      const newSerialNumber = { id: this.generateGUID(), serial_number: '', isNew: true };
      this.localSerialNumbers.push(newSerialNumber);
      this.editableRowId = newSerialNumber.id;
    },

    editRow(rowId) {
      const item = this.localSerialNumbers.find((item) => item.id === rowId);
      if (item) {
        this.originalSerialNumberData[rowId] = _.cloneDeep(item);
      }
      this.editableRowId = rowId;
    },
    async saveRow(item) {
      // Validate that the serial number is not empty
      if (!item.serial_number) {
        this.notifyError("The serial number cannot be empty.");
        return;
      }

      if (item.serial_number.length<4) {
        this.notifyError("The serial number should be at least 5 characters.");
        return;
      }

      // Exclude the item being edited from the quantity check
      const existingSerials = this.localSerialNumbers.filter(
          (i) => !i.isNew && i.id !== item.id
      );

      if (existingSerials.length >= this.quantity) {
        this.notifyWarning("The number of serial numbers exceeds the required quantity.");
        return;
      }

      const index = this.localSerialNumbers.findIndex((i) => i.id === item.id);

      if (index !== -1) {
        // Use Vue's $set to ensure reactivity
        this.$set(this.localSerialNumbers, index, { ...item, isNew: false });
      } else {
        this.notifyError("Error locating the serial number for saving.");
        return;
      }

      const saveResult = await this.saveSerialNumberToServer(item);
      if (saveResult) {
        // Remove from the backup data since save was successful
        delete this.originalSerialNumberData[item.id];
        this.editableRowId = null;
      } else {
        // Restore to previous state if save fails
        this.localSerialNumbers[index] = this.originalSerialNumberData[item.id];
        this.editableRowId = item.id; // Stay in edit mode for retry
      }
    },

    async saveSerialNumberToServer(item) {
      try {
        if (item.isNew) {
          const createdSerial = await this.SalesOrderService.addSerialNumber(
              this.sales_order_id,
              this.dispatch_note_id,
              this.pack_list_id,
              this.pack_list_item_id,
              item.serial_number
          );
          const index = this.localSerialNumbers.findIndex((i) => i.id === item.id);
          if (index !== -1 && createdSerial) {

            debugger;
            // Use $set to ensure the array is reactive
            this.$set(this.localSerialNumbers, index, {
              ...this.localSerialNumbers[index],
              serial_id: createdSerial.serial_id,
              isNew: false,
            });
          }
        } else {
          await this.SalesOrderService.updateSerialNumber(
              this.sales_order_id,
              this.dispatch_note_id,
              this.pack_list_id,
              this.pack_list_item_id,
              item.serial_id,
              item.serial_number
          );
        }
        this.notifySuccess(`Serial number ${item.isNew ? 'created' : 'updated'} successfully.`);
        return true;
      } catch (error) {
        this.notifyError(`Error ${item.isNew ? 'creating' : 'updating'} serial number.`);
        console.error(error);
        return false;
      }
    },

    async deleteRow(rowId) {
      const itemIndex = this.localSerialNumbers.findIndex((i) => i.id === rowId);
      const item = this.localSerialNumbers[itemIndex];
      const backupSerialNumbers = _.cloneDeep(this.localSerialNumbers);

      if (item && !item.isNew) {
        try {
          await this.SalesOrderService.deleteSerialNumber(
              this.sales_order_id,
              this.dispatch_note_id,
              this.pack_list_id,
              this.pack_list_item_id,
              item.serial_id
          );
          this.localSerialNumbers.splice(itemIndex, 1);
          this.notifySuccess("Serial number deleted successfully.");
        } catch (error) {
          this.notifyError("Error deleting serial number.");
          console.error(error);
          this.localSerialNumbers = backupSerialNumbers;
        }
      } else {
        this.localSerialNumbers.splice(itemIndex, 1);
      }

      this.editableRowId = null;
      this.checkAndAddInitialSerialNumber();
    },

    cancelEdit(item) {
      debugger;
      if (item.isNew) {
        this.localSerialNumbers = this.localSerialNumbers.filter((i) => i.id !== item.id);
      } else {
        const originalData = this.originalSerialNumberData[item.id];
        if (originalData) {
          const index = this.localSerialNumbers.findIndex((p) => p.id === item.id);
          if (index !== -1) {
            this.localSerialNumbers.splice(index, 1, originalData);
          }
        }
      }
      this.editableRowId = null;
      delete this.originalSerialNumberData[item.id];
      this.checkAndAddInitialSerialNumber();
    },
    isLastRow(rowId) {
      return this.localSerialNumbers[this.localSerialNumbers.length - 1].id === rowId;
    },
    generateGUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },

    async saveBulkSerialNumbers() {
      // Split, trim, and validate serial numbers
      const serials = this.bulkSerialNumbers
          .split(',')
          .map((sn) => sn.trim())
          .filter((sn) => sn.length >= 5);

      // Check if there are valid serial numbers
      if (serials.length <= 0) {
        this.notifyWarning('No valid serial number to add.');
        return;
      }

      // Check if the number of serials exceeds the remaining required quantity
      if (this.quantity < this.localSerialNumbers.filter((item) => !item.isNew).length + serials.length) {
        this.notifyWarning(`You cannot add more than the required quantity of ${this.quantity}.`);
        return;
      }

      try {
        // Call the bulk add function in the service
        const response = await this.SalesOrderService.bulkAddSerialNumbers(
            this.sales_order_id,
            this.dispatch_note_id,
            this.pack_list_id,
            this.pack_list_item_id,
            serials.join(',')
        );

        // Find the first index with an empty serial number value in the localSerialNumbers array
        const emptyIndex = this.localSerialNumbers.findIndex(item => !item.serial_number);

        // Insert the response items just above the first empty serial number item or at the end if none exist
        if (emptyIndex !== -1) {
          this.localSerialNumbers.splice(emptyIndex, 0, ...response);
        } else {
          this.localSerialNumbers.push(...response);
        }

        this.notifySuccess('Serial numbers added successfully.');
        this.toggleBulkAddMode(); // Switch back to DataTable mode after successful save
      } catch (error) {
        this.notifyError('Error adding serial numbers in bulk.');
        console.error(error);
      }
    },
  },
  watch: {
    initialSerialNumbers(newItems) {
      this.localSerialNumbers = _.cloneDeep(newItems);
      this.editableRowId = null;
    },
  },
};
</script>

<style scoped>
.card {
  padding: 20px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>
