<template>
  <div class="flex flex-wrap bg-white p-5 rounded w-full">
    <!-- Header Row -->
    <div class="flex justify-between items-center mb-2 w-full bg-gray-200 p-2 rounded">
      <div class="w-1/8 text-left">
        <label class="uppercase font-semibold text-sm">Qty</label>
      </div>
      <div class="w-2/8 text-left">
        <label class="uppercase font-semibold text-sm">Code</label>
      </div>
      <div class="w-5/8 text-left">
        <label class="uppercase font-semibold text-sm">Description</label>
      </div>
    </div>

    <!-- Data Row -->
    <div class="flex justify-between items-center mb-4 w-full border-b pb-2 px-2">
      <div class="w-1/8 text-left">
        <span>{{ packListItem.quantity_packed }}</span>
      </div>
      <div class="w-2/8 text-left">
        <span>{{ packListItem.product_code }}</span>
      </div>
      <div class="w-5/8 text-left">
        <span>{{ packListItem.description }}</span>
      </div>
    </div>

    <!-- Serial Numbers Component -->
    <div class="w-full py-2 mt-10">
      <SerialNumber
          :initialSerialNumbers="packListItem.serial_number || []"
          :sales_order_id="sales_order_id"
          :dispatch_note_id="dispatch_note_id"
          :pack_list_id="pack_list_id"
          :pack_list_item_id="packListItem.pack_list_item_id"
          :quantity="convertToInt(packListItem.quantity_packed)"
          @serialNumbers="updateSerialNumbers"
      />
    </div>
  </div>
</template>

<script>
import SerialNumber from "@/views/HR/Sales/SerialNumber/SerialNumber.vue";

export default {
  name: "AddSerialNumber",
  components: {
    SerialNumber,
  },
  props: {
    packListItem: {
      required: true,
      type: Object,
    },
    sales_order_id: {
      type: String,
      required: true,
    },
    dispatch_note_id: {
      type: String,
      required: true,
    },
    pack_list_id: {
      type: String,
      required: true,
    },
  },
  methods: {
    convertToInt(value) {

      return parseInt(value, 10);
    },
    updateSerialNumbers(serialNumbers) {
      this.$emit('update-serial-numbers', serialNumbers);
    },
  },
};
</script>
