<template>
  <div>
    <div class="card">
      <TreeTable
          :value="formattedPackItems"
          selectionMode="checkbox"
          :selectionKeys.sync="selectedPackItems"
          :paginator="false"
          :rows="2"
          @node-select="onNodeSelect"
          @node-unselect="onNodeUnselect"
          :rowHover="true"
          :indentation="3"
          :showGridlines="true"
      >
        <!-- Dynamic columns based on configuration -->
        <Column
            v-for="col in columns"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            :expander="col.expander"
        >
          <!-- Render custom template if specified in column config -->
          <template v-if="col.type === 'action'" #body="slotProps">

            <AppButton v-if="status !== 'confirmed'"  :color="getButtonColor(slotProps)" label="" :icon="true" class="mr-1"
                       @click="addSerialNumber(slotProps)">
              <template v-slot:icon>
                <Icon iconType="table-list"/>
              </template>
            </AppButton>

          </template>
        </Column>

      </TreeTable>
    </div>

    <portal to="overlay-outlet">
      <panel :showing="addSerialNumberPanelOpen" @close="onSerialNumberPanelClose" title="Add Serial Number">
        <AddSerialNumber :packListItem="selectedPackListItem" :sales_order_id="sales_order_id"
                         :dispatch_note_id="dispatch_note_id" :pack_list_id="pack_list_id"
                         />
      </panel>
    </portal>

  </div>
</template>

<script>

import Panel from "@/components/Panel.vue";
import AddSerialNumber from "@/views/HR/Sales/SerialNumber/AddSerialNumber.vue";
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";

export default {

  components: {
    AddSerialNumber,
    Panel,
    AppButton,
    Icon
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    sales_order_id: String,
    dispatch_note_id: String,
    pack_list_id: String,
    status: String,
  },
  data() {
    return {
      columns: null,
      formattedPackItems: [], // Formatted data for the TreeTable
      selectedPackItems: null,
      addSerialNumberPanelOpen: false,
      selectedPackListItem:null,
    };
  },
  watch: {
    items: {
      immediate: true,
      handler(newItems) {
        this.formattedPackItems =  this.formatPackListItems(newItems)
         this.setInitialSelectedState();
      },
    },
  },
  created() {
      this.columns = [
      {field: 'product_code', header: 'Boston Code', expander: true},
      {field: 'description', header: 'Item Description'},
      {field: 'quantity_packed', header: 'QTY.'},
      {field: 'action', header: '',type:'action'},
    ];
  },
  methods: {

    getButtonColor(tree) {

      if (!tree || !tree.node || !tree.node.data) {
        return 'gray';
      }
      const data = tree.node.data;

      if (data.quantity_packed === (data.serial_number && data.serial_number.length ? data.serial_number.length : 0)) {
        return 'green';
      } else if((data.serial_number && data.serial_number.length ? data.serial_number.length : 0)>0){
        return 'yellow';
      }
      else {
        return 'gray';
      }
    },

    onSerialNumberPanelClose() {
      this.addSerialNumberPanelOpen = false
      this.$emit('panel-closed');
    },

    addSerialNumber(tree) {

      debugger;
      if(!tree && !tree.node && !tree.node.data ) {
        return
      }

      this.selectedPackListItem=tree.node.data;

      this.addSerialNumberPanelOpen = true;
    },

    setInitialSelectedState() {
      this.items.forEach(item => {


        const isChecked = item.is_packed || false; // Default to false if undefined
        const isPartialChecked = item.partial_checked || false; // Default to false if undefined

        // If the item is packed or partially checked, set it in selectedPackItems
        if (isChecked || isPartialChecked) {
          this.selectedPackItems = {
            ...this.selectedPackItems,
            [item.pack_list_item_id]: {
              checked: isChecked,
              partialChecked: isPartialChecked,
            }
          };
        }
      });
    },



    setInitialSelectedState1() {
      const traverseAndSetSelectedState = (nodes) => {
        nodes.forEach(node => {
          // Check if the node is packed and mark it as selected
          if (node.data && node.data.is_packed) {
            this.selectedPackItems = {
              ...this.selectedPackItems,
              [node.key]: { checked: true, partialChecked: false },
            };
          }

          // Handle children recursively
          if (node.children && node.children.length > 0) {
            traverseAndSetSelectedState(node.children);

            // Check if all or some children are checked
            const allChildrenChecked = node.children.every(child =>
                this.selectedPackItems[child.key] && this.selectedPackItems[child.key].checked
            );
            const someChildrenChecked = node.children.some(child =>
                this.selectedPackItems[child.key] && this.selectedPackItems[child.key].checked
            );

            // Mark parent as partially checked if not all children are fully selected
            if (someChildrenChecked && !allChildrenChecked) {
              this.selectedPackItems = {
                ...this.selectedPackItems,
                [node.key]: { checked: false, partialChecked: true },
              };
            }
          }
        });
      };

      traverseAndSetSelectedState(this.formattedPackItems);
    },


    formatPackListItems(items, parentId = null, level = 0, parentKey = '') {
      return items
          .filter(item => item.parent_id == parentId)
          .map((item, index) => {
            const currentKey = parentKey === '' ? `${index}` : `${parentKey}-${index}`; // Generate hierarchical keys
            const children = this.formatPackListItems(items, item.pack_list_item_id, level + 1, currentKey);
            return {
              key: item.pack_list_item_id,
              data: {
                product_code: item.product_code,
                description: item.description,
                quantity_packed: item.quantity_packed,
                is_packed: item.is_packed,
                pack_list_item_id:item.pack_list_item_id,
                serial_number:item.serial_number,
              },
              children: children.length > 0 ? children : [], // Add children if present
            };
          });
    },

    onNodeSelect(node) {

      this.processNodeSelection(node, true);
    },
    onNodeUnselect(node) {
      this.processNodeSelection(node, false);
    },
    processNodeSelection(node, isChecked) {

      // const selectedItems = this.constructPackListUpdate(node, isChecked);
      const selectedItems = this.selectedPackItems;

      const percentage = this.calculateSelectedPercentage();
      this.$emit('update-selected-items', {selectedItems , percentage });
    },

    calculateSelectedPercentage() {
      if (!this.selectedPackItems || Object.keys(this.selectedPackItems).length === 0) {
        return 0; // Return 0 if selectedPackItems is null or empty
      }

      // Get the parent items from this.items where parent_id is null
      const parentItems = this.items.filter(item => item.parent_id === null);

      if (parentItems.length === 0) {
        return 0; // Avoid division by zero
      }

      let fullyCheckedParents = 0;

      // Iterate over parentItems and check if they are in selectedPackItems
      for (const parentItem of parentItems) {
        const selectedItem = this.selectedPackItems[parentItem.pack_list_item_id];
        if (selectedItem && !selectedItem.partialChecked && selectedItem.checked) {
          fullyCheckedParents++;
        }
      }

      const percentage = (fullyCheckedParents / parentItems.length) * 100;
      return Math.round(percentage); // Return whole number rounded percentage
    },

    constructPackListUpdate(node, isChecked) {
      const itemsToUpdate = [];

      // Add the current node to the update list
      itemsToUpdate.push({
        pack_list_item_id: node.data.pack_list_item_id,
        is_packed: isChecked
      });

      // Process children recursively
      if (node.children && node.children.length > 0) {
        for (let i = 0; i < node.children.length; i++) {
          const child = node.children[i];
          itemsToUpdate.push(...this.constructPackListUpdate(child, isChecked));
        }
      }

      // If checked, check siblings and potentially parents
      if (isChecked) {
        this.checkParentIfSiblingsChecked(node, itemsToUpdate);
      } else {
        // If unchecked, include all parent nodes
        const parentIds = this.findParentPackItems(this.formattedPackItems, node.key);
        for (let i = 0; i < parentIds.length; i++) {
          const parentId = parentIds[i];
          if (!this.itemExistsInUpdate(itemsToUpdate, parentId)) {
            itemsToUpdate.push({
              pack_list_item_id: parentId,
              is_packed: false
            });
          }
        }
      }

      return itemsToUpdate;
    },

    findParentPackItems(nodeList, searchForId) {
      for (let i = 0; i < nodeList.length; i++) {
        const node = nodeList[i];
        if (node.key === searchForId) {
          return [];
        }

        if (node.children && node.children.length > 0) {
          const childResult = this.findParentPackItems(node.children, searchForId);
          if (childResult) {
            return [node.key].concat(childResult);
          }
        }
      }
      return null;
    },

    checkParentIfSiblingsChecked(node, itemsToUpdate) {
      const parent = this.findParentNode(node);

      if (parent && parent.children) {
        let allSiblingsChecked = true;

        // Check if all siblings are checked
        for (let i = 0; i < parent.children.length; i++) {
          const sibling = parent.children[i];
          if (!this.selectedPackItems[sibling.key] || !this.selectedPackItems[sibling.key].checked) {
            allSiblingsChecked = false;
            break;
          }
        }

        // If all siblings checked, mark the parent as checked
        if (allSiblingsChecked) {
          itemsToUpdate.push({
            pack_list_item_id: parent.data.pack_list_item_id,
            is_packed: true
          });

          // Recursively check parent's siblings and update parents
          this.checkParentIfSiblingsChecked(parent, itemsToUpdate);
        }
      }
    },

    findParentNode(node) {
      for (let i = 0; i < this.formattedPackItems.length; i++) {
        const item = this.formattedPackItems[i];
        if (item.children && item.children.length > 0) {
          for (let j = 0; j < item.children.length; j++) {
            if (item.children[j].key === node.key) {
              return item;
            }
          }
        }
      }
      return null;
    },

// Helper function to check if the item already exists in the update list
    itemExistsInUpdate(itemsToUpdate, pack_list_item_id) {
      for (let i = 0; i < itemsToUpdate.length; i++) {
        if (itemsToUpdate[i].pack_list_item_id === pack_list_item_id) {
          return true;
        }
      }
      return false;
    }





  },
}
</script>








<style scoped>
.card {
  padding: 1rem;
}
</style>
