<template>
  <div class="card">
    <DataTable :value="dispatchItems" dataKey="_id">
      <!-- Dynamic columns -->
      <Column v-for="col in columns" :key="col.field" :field="col.field" :header="col.header"
              :headerStyle="getHeaderStyle(col)" :styles="{ 'text-align': col.align }">

        <template v-if="col.type === 'date'" #body="slotProps">
          {{ formatTimestamp(slotProps.data[col.field]) }}
        </template>

        <!-- Custom content based on column type -->
        <template v-else-if="col.type === 'actions'" #body="slotProps">
          <div class="flex justify-end items-center">
<!--            <AppButton color="blue" label="" :icon="true" class="mr-1"-->
<!--                       @click="editRow(slotProps.data)">-->
<!--              <template v-slot:icon>-->
<!--                <Icon iconType="edit"/>-->
<!--              </template>-->
<!--            </AppButton>-->
          </div>
        </template>

        <!-- Default content for other columns -->
        <template v-else #body="slotProps">
          {{ slotProps.data[col.field] }}
        </template>

      </Column>

      <template #empty>
        No Items.
      </template>

    </DataTable>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [notificationMixin],
  components: {
    AppButton,
    Icon
  },
  props: {
    initialSalesOrder: {
      type: Object,
      required: true,
    },
    dispatch_note_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dispatchItems: [],
      columns: [
        { field: 'quantity', header: 'Quantity', width: '20%' },
        { field: 'product_code', header: 'Code', width: '20%' },
        { field: 'description', header: 'Description', width: '40%' },
        { header: '', type: 'actions', width: '20%' }
      ]
    };
  },
  watch: {
    initialSalesOrder(newOrder) {
      this.dispatchItems = this.getDispatchItemsByNoteId(newOrder);
    }
  },
  async mounted() {
    this.dispatchItems = this.getDispatchItemsByNoteId(this.initialSalesOrder);
  },
  methods: {
    getDispatchItemsByNoteId(salesOrder) {
      if (!salesOrder || !salesOrder.dispatch_notes) return [];

      const dispatchNote = salesOrder.dispatch_notes.find(note => note.dispatch_note_id === this.dispatch_note_id);
      if (!dispatchNote || !dispatchNote.items) {
        console.error(`Despatch note with ID ${this.dispatch_note_id} not found`);
        return [];
      }

      return dispatchNote.items.map(item => ({
        quantity: item.quantity || 0,
        product_code: item.product_code || 'N/A',
        description: item.description || 'No description available',
        requested_delivery_date: salesOrder.requested_delivery_date,
        dispatch_date: dispatchNote.dispatch_date,
        status: dispatchNote.status || 'Not packed',
        _id: item._id
      }));
    },

    async editRow(rowData) {
      await this.$router.push({
        name: 'dispatch',
        params: {
          sales_order_id: this.initialSalesOrder.sales_order_id,
          dispatch_note_id: this.dispatch_note_id
        }
      });
    },

    getHeaderStyle(col) {
      return {
        width: col.width,
        textAlign: col['header-align'] || 'left'
      };
    },

    formatTimestamp(unixTimestamp) {
      if (!unixTimestamp) {
        return '';
      }
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    }
  }
};
</script>

<style scoped>
.total-amount {
  margin-top: 10px;
  font-weight: bold;
}

::v-deep .column-h-text-left .p-column-header-content {
  text-align: left;
  display: block !important;
}

::v-deep .column-h-text-center .p-column-header-content {
  text-align: center;
  display: block !important;
}

::v-deep .column-h-text-right .p-column-header-content {
  text-align: right;
  display: block !important;
}
</style>
