<template>
  <form
      @submit.prevent="submitDispatch"
      autocomplete="off"
      class="w-full max-h-screen bg-background flex flex-col relative"
      :class="{ 'overflow-y-hidden': isBusy }"
  >
    <div
        v-show="isBusy"
        class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10"/>
    </div>
    <page-header :title="pageTitle" :info="getDispatchNoteNo"  back-to="sales"  :enableLogicalBack=true>

      <!-- Current Step Buttons -->
      <div v-for="button in currentStepButtons" :key="button.label" class="inline-block">
        <AppButton
            :color="button.color"
            :label="button.label"
            class="ml-2"
            @click="button.action"
            :icon="!!button.icon"
        >
          <template v-slot:icon v-if="button.icon">
            <Icon :iconType="button.icon" class="mr-2"/>
          </template>
        </AppButton>
      </div>

    </page-header>


    <main class="pr-6 pl-6   flex-1 overflow-y-scroll">
      <div class="flex flex-col md:flex-row items-stretch justify-between mt-6">

        <!-- Main Column 3 -->
        <div class="flex-1 bg-white p-4 rounded md:rounded-none mr-6 flex flex-col justify-between">
          <div>
            <div class="flex items-center mb-2">
              <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
                Delivery To<span class="text-red-400">*</span>
              </h3>
              <hr class="flex-1 border-primary ml-4"/>
            </div>
            <div>
              <ContactAutocomplete
                  class="w-full"
                  :disabled="true"
                  v-model="delivery_to"
                  @contactSelected="handleDeliveryToSelected"
                  @contactCleared="handleDeliveryToCleared"
                  :forceSelection="true"/>
            </div>
            <div class="w-full flex">
              <ContactInfoCard :contact="delivery_to"></ContactInfoCard>
            </div>
          </div>
          <div class="flex justify-end mt-4">
<!--            <AppOutlinedButton-->
<!--                label="Add New"-->
<!--                color="indigo"-->
<!--                @click="addNewContact"-->
<!--                :icon=true-->
<!--            >-->
<!--              <template v-slot:icon>-->
<!--                <Icon iconType="add" class="mr-2"/>-->
<!--              </template>-->
<!--            </AppOutlinedButton>-->
          </div>
        </div>

        <!-- Main Column 3 -->
        <div class="flex-2 bg-white p-4 rounded md:rounded-none">
          <div class="flex items-center mb-2">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
              Set Details
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <div class="grid grid-cols-3 gap-2">
            <!-- First Main Column (66%) -->
            <div class="col-span-2 grid grid-cols-2 gap-2">
              <!-- First Sub-Column (50%) -->
              <div class="flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Order Picked by <span class="text-red-400">*</span></label>
                <StaffAutocomplete v-model="pack_list.picked_by"/>
              </div>
              <!-- Second Sub-Column (50%) -->
              <div class="flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Picking date <span class="text-red-400">*</span></label>
                <Calendar
                    v-model="pack_list.picking_date"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                />
              </div>
              <!-- Order Notes (full width) -->
              <div class="col-span-2 grid grid-cols-2 gap-2">
                <!-- First Sub-Column (50%) -->
                <div class="flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">Checked by <span class="text-red-400">*</span></label>
                  <StaffAutocomplete v-model="pack_list.checked_by"/>
                </div>

                <!-- Second Sub-Column (50%) -->
                <div class="flex flex-col px-5 mb-3">
                </div>
              </div>

            </div>
            <!-- Second Main Column (33%) -->
            <div class="col-span-1 flex flex-col items-center gap-2">
              <!-- Label vertically aligned to the top -->
              <label class="uppercase font-semibold text-sm text-center w-full">
                PENDING STATUS
              </label>

              <!-- Knob centered horizontally and vertically -->
              <div class="flex items-center justify-center h-full">
                <Knob :value="completedPackageValue" :min="0" :max="100" :size="130"
                      valueColor="#4CAF50" rangeColor="#ff9b00" :strokeWidth="6" title="report" valueTemplate="{value}%" />
              </div>
            </div>


          </div>
        </div>

        <div class="flex-1  p-4 rounded md:rounded-none">
        </div>


      </div>

      <!-- Select Products -->
      <div class="w-full bg-white mt-6 mb-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-2">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Select Products
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
            <AppButton v-if="pack_list.status !== 'confirmed'" color="indigo"
                       label="Save Packing List Items" @click="updatePackListItems"
                       :icon="true" class="ml-4">
              <template v-slot:icon>
                <Icon iconType="save" class="mr-2"/>
              </template>
            </AppButton>


          </div>
          <PackListItems  v-if="pack_list.items" :items="pack_list.items" :sales_order_id="sales_order_id" :dispatch_note_id="dispatch_note_id" :pack_list_id="pack_list_id"
                         @update-selected-items="handlePackListUpdate" ref="packListItemsRef"
                          @panel-closed="handleSerialNumberPanelClose" :status="pack_list.status"
          ></PackListItems>
        </div>

      </div>
      <portal to="overlay-outlet">
        <PDFViewer :showing="pdfViewerData.isShowingViewer" :pdfBlob="pdfViewerData.pdfData"
                   :documentName="pdfViewerData.documentName" @close="closeViewer"/>
      </portal>

    </main>

  </form>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Panel from "@/components/Panel.vue";
import Spinner from "@/components/Spinner.vue";
import ContactAutocomplete from '@/components/AutoComplete/ContactAutoComplete.vue';
import AppButton from '@/components/Button.vue';
import AppOutlinedButton from '@/components/OutlinedButton.vue';
import Icon from '@/components/Icon.vue';
import notificationMixin from "@/mixins/notificationMixin";
import ManageFiles from "@/views/HR/Engineer/JobSheet/Components/ManageFiles.vue";
import PackListItems from "@/views/HR/Sales/PackList/PackListItems.vue";
import ContactInfoCard from "@/components/Contacts/InfoCard.vue";
import StaffAutocomplete from "@/components/AutoComplete/StaffAutoComplete.vue";
const Toggle = () => import("@/components/Toggle.vue");

import DispatchNoteOrderItems from "@/views/HR/Sales/Dispatch/DispatchNoteOrderItems.vue";
const PDFViewer = () => import("@/components/PDFViewer.vue");


export default {
  name: "ManagePurchase",
  mixins: [notificationMixin],
  components: {
    StaffAutocomplete,
    PageHeader,
    Panel,
    Spinner,
    ContactAutocomplete,
    AppButton,
    Icon,
    Toggle,
    ManageFiles,
    ContactInfoCard,
    AppOutlinedButton,
    DispatchNoteOrderItems,
    PackListItems,
    PDFViewer,
  },
  props: {
    sales_order_id: String,
    dispatch_note_id: String,
    pack_list_id: String,
  },
  data() {
    return {
      completedPackageValue:0,
      isBusy: false,
      addProductPanelOpen: false,
      sales:{},
      pack_list: {
        picking_date:null,
      },

      delivery_to: null,
      pdfViewerData: {
        documentName: "",
        pdfData: null,
        isShowingViewer: false,
      },
    };
  },
  async mounted() {
    this.isBusy = true;

    if (!this.sales_order_id) {
      if (this.$store.state.user && this.$store.state.user.staff_id) {
        this.setDefaultCreatedBy()
      }
      this.setDefaultDates();
      this.isBusy = false;
      return;
    }

    if (this.sales_order_id) {
      await this.loadPackListAndApply();
      this.setDefaultDates();
      this.isBusy = false;
      return;
    }

    this.isBusy = false;

  },

  computed: {

    pageTitle() {
      return this.sales_order_id ? "Despatch Packing List" : "Create Sales Order";
    },

    getDispatchNoteNo() {
      if (!this.sales || !this.sales.dispatch_notes) {

        return "";
      }

      const dispatchNote = this.sales.dispatch_notes.find(note => note.dispatch_note_id === this.dispatch_note_id);

      if (!dispatchNote) {

        return "";
      }

      return dispatchNote.dispatch_note_no ? dispatchNote.dispatch_note_no : "";
    },


    currentStepButtons() {
      const stepButtons = {
        'confirmed': [
          {label: 'Packing List Report', color: 'green', action: this.printPackList, icon: 'print'},
        ],
      };

      return stepButtons[this.pack_list.status] || [
        {label: 'Save Packing List', color: 'blue', action: this.savePackList, icon: 'save'},
        {label: 'Confirm Picked', color: 'green', action: this.validateAndUpdatePackListItems, icon: 'thumb-up'},
        {label: 'Packing List Report', color: 'green', action: this.printPackList, icon: 'print'},

      ];
    },
  },
  methods: {

    async handleSerialNumberPanelClose() {

      await this.loadPackListAndApply();
    },

    async validateAndUpdatePackListItems() {
      try {
        if (!this.validateRequiredFields()) {
          return;
        }

        const selectedItems = this.$refs.packListItemsRef.selectedPackItems || {}; // Ensure selectedItems is an object
        const allItems = this.$refs.packListItemsRef.items || []; // Ensure allItems is an array

        if (!Object.keys(selectedItems).length) {
          this.notifyError('Cannot proceed. No Items are picked.');
          return;
        }

        // Check for partial checked items
        const hasPartialChecked = Object.values(selectedItems).some(item => item && item.partialChecked);
        if (hasPartialChecked) {
          this.notifyError('Cannot proceed. Some items are partially checked.');
          return;
        }

        // Check if there are selected items with checked=true but is_packed=false
        const hasSelectedUnpackedItems = allItems.some(item => {
          const selectedItem = selectedItems[item.pack_list_item_id];
          return selectedItem && selectedItem.checked && !item.is_packed;
        });

        if (hasSelectedUnpackedItems) {
          const confirmation = await this.confirmAction({
            message: 'Some selected items are marked as picked but not saved. Do you want to update the Pack List Item Packed Status?',
            header: 'Confirm Action',
            acceptLabel: 'Yes',
            rejectLabel: 'No'
          });

          if (!confirmation) {
            return; // Stop the process if user rejects
          }

          // Update pack list items logic goes here (e.g., mark as packed)
          await this.updatePackListItems(false);
        }

        // Find any items that are in the original list but not selected
        const hasUnpackedItems = allItems.some(item => {
          const selectedItem = selectedItems[item.pack_list_item_id];
          return !selectedItem || !item.is_packed;
        });

        let confirmation = true;

        // Ask for confirmation only if there are unpacked items
        if (hasUnpackedItems) {
          confirmation = await this.confirmAction({
            message: 'Some items in this Despatch Note are not fully picked. Do you want to copy the remaining items to a new Despatch Note?',
            header: 'Confirm Action',
            acceptLabel: 'Yes',
            rejectLabel: 'No'
          });
        }

        const formattedPackList = this.getAllPackListData(this.pack_list);

        delete formattedPackList.items

        this.isBusy = true;

        await this.SalesOrderService.confirmAndRemoveNotPackedItems(
            this.sales_order_id,
            this.dispatch_note_id,
            this.pack_list_id,
            formattedPackList
        );

        if (confirmation && hasUnpackedItems) {
          await this.SalesOrderService.createDispatchNote(
              this.sales_order_id,
              {}
          );
        }

        // Reload and apply the updated pack list
        await this.loadPackListAndApply();
        this.notifySuccess('Pack list items updated successfully');
      } catch (error) {
        this.isBusy = false;
        console.error('Error updating pack list items:', error);
        this.notifyError('Failed to update pack list items');
      } finally {
        this.isBusy = false;
      }
    },


    async updatePackListItems(reload = true) {
      try {
        const selectedItems = await this.$refs.packListItemsRef.selectedPackItems;

        this.isBusy = true;

        // Call the API to update the selected pack list items
        await this.SalesOrderService.updateMultiplePackListItems(this.sales_order_id, this.dispatch_note_id, this.pack_list_id, selectedItems);

        // After successful update, reload the pack list if reload is true
        if (reload) {
          await this.loadPackListAndApply();
        }

        this.isBusy = false;

        this.notifySuccess('Pack list items updated successfully');
      } catch (error) {
        this.isBusy = false;
        console.error('Error updating pack list items:', error);
        this.notifyError('Failed to update pack list items');
      }
    },


    async handlePackListUpdate({ selectedItems, percentage }) {
      try {

        // this.isBusy = true;
        // // Call the API to update the selected pack list items
        // await this.SalesOrderService.updateMultiplePackListItems(this.sales_order_id, this.dispatch_note_id,this.pack_list_id , selectedItems);
        //
        // // After successful update, reload the pack list
        // await this.loadPackListAndApply();
        //
        // this.isBusy = false;
        //
        // this.notifySuccess('Pack list items updated successfully');
      } catch (error) {
        this.isBusy = false;
        console.error('Error updating pack list items:', error);
        this.notifyError('Failed to update pack list items');
      }
    },

    async loadPackListAndApply() {
      const dispatch = await this.loadPackListById();
      this.pack_list = await this.formatPackListData(dispatch);

      // After loading and formatting the pack_list, calculate the percentage
      const percentage = this.calculatePackListPercentage(this.pack_list.items);
      this.completedPackageValue = percentage;
    },

    calculatePackListPercentage(items) {
      if (!items || items.length === 0) {
        return 0;
      }

      const parentItems = items.filter(item => item.parent_id === null);

      if (parentItems.length === 0) {
        return 0;
      }

      let fullyCheckedParents = 0;

      for (const parentItem of parentItems) {
        if (parentItem.is_packed === true) {
          fullyCheckedParents++;
        }
      }

      const percentage = (fullyCheckedParents / parentItems.length) * 100;
      return Math.round(percentage); // Return rounded percentage
    },

    async addNewContact() {

      await this.$router.push({
        name: 'contacts',
      });
    },


    setDefaultDates() {

      if (!this.pack_list.picking_date) {
        this.pack_list.picking_date = this.$moment().toDate();
      }
    },

    setDefaultCreatedBy() {
      this.sales.created_by = {
        name: `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`,
        staff_id: this.$store.state.user.staff_id
      };
    },

    convertPackListDatesFromUnix(pack) {
      const convertUnixToDate = (unixTimestamp) => {
        return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
      };

      // Convert order_date
      if (pack.picking_date) {
        pack.picking_date = convertUnixToDate(pack.picking_date);
      }
      return pack;
    },


    async formatSalesDataAndDeliveryInfo(sales) {
      let formattedSales = JSON.parse(JSON.stringify(sales));

      function findDefault(items) {
        return items && items.length > 0 ? items.find(item => item.is_default) || null : null;
      }

      // Handle delivery to information
      if (formattedSales.delivery_to_contact && formattedSales.delivery_to_contact.contact_id && formattedSales.delivery_to_contact.name) {
        this.delivery_to = {
          name: formattedSales.delivery_to_contact.name,
          contact_id: formattedSales.delivery_to_contact.contact_id
        };

        this.delivery_to.address = findDefault(formattedSales.delivery_to_contact.addresses);

        this.delivery_to.people = findDefault(formattedSales.delivery_to_contact.people);
      }

    },


    async formatPackListData(pack) {
      let formattedPackList = JSON.parse(JSON.stringify(pack));
      // Formatting dates
      formattedPackList = this.convertPackListDatesFromUnix(formattedPackList);

      return formattedPackList;
    },

    handleDeliveryToSelected: function (selectedCustomer) {

    },

    handleDeliveryToCleared(selectedContact) {

    },

    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },

    async loadPackListById() {
      try {
        // Fetch the sales order data by sales_order_id
        const salesData = await this.SalesOrderService.getSalesOrderById(this.sales_order_id);

        if (!salesData || !salesData.dispatch_notes) {
          console.error("Sales order or despatch notes not found");
          return null; // Handle the case where there is no sales data or dispatch notes
        }

        await this.formatSalesDataAndDeliveryInfo(salesData);

        this.sales = salesData;

        // Validate and find the dispatch note by this.dispatch_note_id
        const dispatchNote = salesData.dispatch_notes.find(note => note.dispatch_note_id === this.dispatch_note_id);

        if (!dispatchNote) {
          console.error(`Despatch note with ID ${this.dispatch_note_id} not found`);
          return null; // Handle case where dispatch note is not found
        }

        // Validate and find the pack list in the found dispatch note
        if (!dispatchNote.pack_lists || dispatchNote.pack_lists.length === 0) {
          console.error("Pack lists not found in the despatch note");
          return null; // Handle case where there are no pack lists
        }

        const packList = dispatchNote.pack_lists.find(list => list.pack_list_id === this.pack_list_id);

        if (!packList) {
          console.error(`Pack list with ID ${this.pack_list_id} not found`);
          return null; // Handle case where pack list is not found
        }

        // Return the found pack list
        return packList;

      } catch (error) {
        this.isBusy = false;
        console.error("Error loading sales, despatch note, or pack list:", error);
        return null; // Handle the error
      }
    },


    convertPackListDatesToUnix(pack) {
      const convertDateToUnix = (date) => {
        if (date instanceof Date) {
          return Math.floor(date.getTime() / 1000);
        } else if (typeof date === 'string') {
          const momentDate = this.$moment(date, 'DD-MM-YYYY', true);
          if (momentDate.isValid()) {
            return momentDate.unix();
          } else {
            const isoMomentDate = this.$moment(date, this.$moment.ISO_8601);
            if (isoMomentDate.isValid()) {
              return isoMomentDate.unix();
            }
          }
        }
        console.error('Invalid or unsupported date format:', date);
        return null;
      };

      // Convert dispatch
      if (pack.picking_date) {
        pack.picking_date = convertDateToUnix(pack.picking_date);
      }

      return pack;
    },



    getAllPackListData(pack) {

      let convertedPack = JSON.parse(JSON.stringify(pack));

      convertedPack = this.convertPackListDatesToUnix(convertedPack);

      return convertedPack;
    },

    onRowClick(event) {

      this.Edit(event.data);
    },

    goToPackList: async function () {


    },

    validateRequiredFields() {
      const requiredFields = [
        { field: this.pack_list.picking_date, name: 'Picked Date' },
        { field: this.pack_list.picked_by, name: 'Picked By' },
        { field: this.pack_list.checked_by, name: 'Checked By' }
      ];

      for (const field of requiredFields) {
        if (!field.field) {
          this.notifyError(`${field.name} is required.`);
          return false;
        }
      }
      return true;
    },

    savePackList: async function () {

      this.isBusy = true;
      const formattedPackList = this.getAllPackListData(this.pack_list);
      try {
        if (this.pack_list) {
          await this.SalesOrderService.updatePackList(
              this.sales_order_id,
              this.dispatch_note_id,
              this.pack_list_id,
              formattedPackList,
          );
          this.notifySuccess("Pack List updated successfully");

          await this.loadPackListAndApply();
        } else {

          this.notifyError("Couldn't create Pack List");
        }

      } catch (error) {
        let errorMessage = this.sales_order_id
            ? "Couldn't update Pack List"
            : "Couldn't create Pack List";
        this.notifyError(errorMessage);
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    submitDispatch: async function (event) {
      event.preventDefault();
    },

    closeViewer() {
      this.pdfViewerData = {
        pdfData: null,
        documentName: "",
        isShowingViewer: false,
      };
    },

    async printPackList() {
      if (!this.sales_order_id || !this.dispatch_note_id || !this.pack_list_id) {
        this.notifyWarning("Missing sales order, despatch note, or pack list ID. Please ensure all required fields are filled.");
        return;
      }

      try {
        const response = await this.SalesOrderService.generatePackListReport(this.sales_order_id, this.dispatch_note_id, this.pack_list_id, 'packing-list');

        this.pdfViewerData = {
          pdfData: response,
          documentName: 'Pack List Report',
          isShowingViewer: true,
        };
      } catch (error) {
        console.error('Error loading PDF:', error);
        this.notifyWarning("Failed to load the PDF document. Please try again.");
      }
    },


  }
}
;
</script>

<style>
.hide-header .p-datatable-thead {
  display: none;
}

</style>

<style scoped>
.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

</style>